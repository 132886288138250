import { appendDictToFormData, dateToString } from "./GeneralFunctions";

const Excel = require("exceljs");
const axios = require("axios");
const FormData = require("form-data");
const config = require("../res/config.json");

/**
 *
 * @param {Number} number
 * @param {Boolean} override
 * @returns String or String[]
 *
 *
 */
function parseString(number, override = false) {
  if (number === null) return null;
  let str = number.toString();
  if (str.split("|").length === 1 && !override) {
    return str;
  }
  return str.split("|");
}

/**
 *
 * @param {File} file
 *
 * Parses inputed xlsx file into json and POST it to server.
 * Used to change server files such as productlists, labels or variables.
 *
 */
export function addExcelFile(file) {
  const [company, countryCode, type] = file.name
    .split(".")[0]
    .toLowerCase()
    .split("-");
  const notProduct = type === "variables" || type === "labels";

  let fileAsJson = {
    params: {
      company: company,
      countryCode: countryCode,
      type: type,
    },
    data: notProduct
      ? {}
      : {
          productList1: {},
          productList2: {},
        },
  };

  const reader = new FileReader();
  const wb = new Excel.Workbook();

  reader.readAsArrayBuffer(file);
  reader.onload = () => {
    const buffer = reader.result;
    wb.xlsx.load(buffer).then(() => {
      const ws = wb.getWorksheet(1);
      let contractLengths = [];
      for (let row = 2; row <= ws.actualRowCount; row++) {
        if (notProduct) {
          fileAsJson["data"][ws.getRow(row).getCell(1).value] = parseString(
            ws.getRow(row).getCell(2).value
          );
        } else {
          for (let productList = 1; productList <= 2; productList++) {
            let exponent = productList - 1;
            if (ws.getRow(row).getCell(2 + 3 * exponent).value === null) break;
            const productName = ws.getRow(row).getCell(2 + 3 * exponent).value;
            const price = parseString(
              ws.getRow(row).getCell(3 + 3 * exponent).value,
              true
            );
            const service = parseString(
              ws.getRow(row).getCell(4 + 3 * exponent).value,
              true
            );
            fileAsJson["data"]["productList" + productList][productName] = {
              price: price,
              service: service,
            };
          }
          if (ws.getRow(row).getCell(1).value === null) continue;
        }
        contractLengths.push(parseFloat(ws.getRow(row).getCell(1).value));
      }
      if (!notProduct) {
        contractLengths.sort((a, b) => a - b);
        fileAsJson["data"]["contractLengths"] = contractLengths;
      }
      axios.post(config.ROOT + "excel", fileAsJson, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Credentials": true,
        },
      });
    });
  };
}

/**
 *
 * @param {File} file
 *
 * POST request image file to server.
 */
export function addImageFile(file) {
  let data = new FormData();
  data.append("file", file);
  axios
    .post(config.ROOT + "image", data, {
      withCredentials: true,
      headers: {
        Accept: "multipart/form-data",
        "Access-Control-Allow-Credentials": true,
      },
    })
    .then((response) => console.log(response.status));
}

/**
 *
 * @param {string} countryCode
 * @param {string} formType
 *
 * GET request to server and returns json.
 */
export function getCountryInformation(company, countryCode, formType) {
  return axios
    .get(
      "https://0yhkzrgta0.execute-api.eu-north-1.amazonaws.com/Continous/" +
        "all",
      {
        params: {
          company: company,
          countryCode: countryCode,
          form: formType,
        },
      }
    )
    .then((response) => console.log(response))
    .catch((error) => console.log(error));
}

/**
 *
 * @param {string} countryCode
 * @param {string} formType
 *
 * GET request to server and returns json.
 */
export async function getCountryInformationDB(company, countryCode, formType) {
  try {
    const contractLengthsResponse = await axios.get(
      `${config.ROOT}contractlength/allbycountryformtype/${countryCode}/${formType}`
    );
    let contractLengths = [];
    for (const length of contractLengthsResponse.data) {
      contractLengths.push(length.duration);
    }
    const contractLength = Math.max(...contractLengths);

    const variablePromise = axios.get(
      `${config.ROOT}forminfo/variables/${countryCode}`
    );
    const labelsPromise = axios.get(
      `${config.ROOT}forminfo/labels/${countryCode}`
    );
    const tooltipsPromise = axios.get(
      `${config.ROOT}forminfo/tooltips/${countryCode}`
    );
    const productsPromise = axios.get(
      `${config.ROOT}forminfo/products/${countryCode}/${formType}/${contractLength}`
    );
    const headerTextPromise = axios.get(
      `${config.ROOT}forminfo/headertext/${countryCode}/${formType}`
    );
    const accessoriesPromise = axios.get(
      `${config.ROOT}forminfo/allaccessories`
    );

    const [
      variableResponse,
      labelsResponse,
      productsResponse,
      headerTextResponse,
      tooltipsResponse,
      accessoriesResponse,
    ] = await Promise.all([
      variablePromise,
      labelsPromise,
      productsPromise,
      headerTextPromise,
      tooltipsPromise,
      accessoriesPromise,
    ]);
    return {
      variableResponse,
      labelsResponse,
      tooltipsResponse,
      productsResponse,
      headerTextResponse,
      contractLengths,
      accessoriesResponse,
    };
  } catch (error) {
    console.error("ERROR:", error);
  }
}

export function getAccessories(
  countryName,
  formTypeName,
  contractLength,
  productName
) {
  return axios
    .get(
      `${config.ROOT}forminfo/accessories/${countryName}/${formTypeName}/${contractLength}/${productName}`
    )
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function passwordCheck(password) {
  const url = `${config.ROOT}forminfo/passwordCheck/${password}`;
  const uniqueQueryParam = `_=${new Date().getTime()}`;

  return axios
    .get(`${url}?${uniqueQueryParam}`)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function getDefaultPricesAccessories(
  country,
  formType,
  productName,
  contractLength,
  accessoryName
) {
  return axios
    .get(
      `${
        config.ROOT
      }forminfo/accessoryPrice/${country}/${formType}/${contractLength}/${productName}/${encodeURIComponent(
        accessoryName
      )}`
    )
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function getDefaultPrices(
  country,
  formType,
  productName,
  contractLength
) {
  return axios
    .get(
      `${config.ROOT}forminfo/productprice/${country}/${formType}/${productName}/${contractLength}`
    )
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function creditCheckSe(ssn) {
  const requestBody = {
    SSN: ssn,
  };
  return axios
    .post(`${config.ROOT}credit-safe-se-check/fetch-credit`, requestBody)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function creditSearchSe(ssn) {
  const requestBody = {
    SSN: ssn,
  };
  return axios
    .post(`${config.ROOT}credit-safe-se-check/fetch-basic`, requestBody)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function personInfoCheckNo(id) {
  const requestBody = {
    id: id,
  };
  return axios
    .post(`${config.ROOT}credit-safe-no-search`, requestBody)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function creditCheckNo(id, purchasePrice) {
  const requestBody = {
    id: id,
    purchasePrice: purchasePrice,
  };
  return axios
    .post(`${config.ROOT}credit-safe-no-report`, requestBody)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function experianRequest(requestBody) {
  return axios
    .post(`${config.ROOT}experian`, requestBody)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function experianDKRequest(requestBody) {
  return axios
    .post(`${config.ROOT}experian-dk-check`, requestBody)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function experianDKSearch(id) {
  const requestBody = {
    number: id,
  };
  return axios
    .post(`${config.ROOT}experian-dk-search`, requestBody)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function createContract(requestBody) {
  return axios
    .post(`${config.ROOT}contract-no`, requestBody)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function getContractPDF(requestBody) {
  return axios
    .post(`${config.ROOT}create-contract-pdf/getContract`, requestBody)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function getContractOnboardingPDF(requestBody) {
  return axios
    .post(
      `${config.ROOT}create-contract-pdf/getContractOnboardingPDF`,
      requestBody
    )
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function verifiedRequest(requestBody) {
  return axios
    .post(`${config.ROOT}verified`, requestBody)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export function getLabels(countryCode) {
  return axios
    .get(`${config.ROOT}forminfo/labels/${countryCode}`)
    .then((response) => {
      let labels = {};
      for (const label of response.data) {
        labels[label.Field.name] = label.value;
      }
      return labels;
    })
    .catch((error) => console.log(error));
}

export function sendEmailToAddress(
  text,
  address,
  subject,
  base64PDF = null,
  filename = null
) {
  const payload = {
    text: text,
    address: address,
    subject: subject,
  };

  // Include attachment only if provided
  if (base64PDF && filename) {
    payload.attachment = {
      base64: base64PDF,
      filename: filename,
    };
  }

  return axios
    .post(
      config.ROOT + "email/sendEmailToAddress", // Adjust the route to match your controller route
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response)
    .catch((error) => {
      console.log("Error sending email:", error);
      throw error; // Rethrow the error if you want to handle it further up the chain
    });
}

export function postOnboarding(countryCode, csvData) {
  return axios
    .post(
      config.ROOT + "onboarding",
      {
        countryCode: countryCode,
        csvData: csvData,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response)
    .catch((error) => console.log(error));
}

export function getOnboarding() {
  return axios
    .get(config.ROOT + "getonboarding", {})
    .then((response) => response)
    .catch((error) => console.log(error));
}

export function getFileAsExcel(company, countryCode, type) {
  const fileName = `${company}-${countryCode}-${type}`;
  axios({
    url: config.ROOT + "excel",
    method: "GET",
    withCredentials: true,
    responseType: "blob",
    params: {
      countryCode: countryCode,
      type: type,
      company: company,
    },
    headers: {
      "Access-Control-Allow-Credentials": true,
    },
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName + ".xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      return error.response.status;
    });
}

export function login(username, password) {
  return axios
    .post(config.ROOT + "login", {
      username: username,
      password: password,
    })
    .then((response) => response)
    .catch((error) => error);
}

export function logout() {
  return axios
    .get(config.ROOT + "logout")
    .then((response) => response)
    .catch((error) => error);
}

export function isLoggedIn() {
  return axios
    .get(config.ROOT + "isLoggedIn")
    .then((response) => response)
    .catch((error) => error);
}

export async function getContractNumber() {
  return await axios
    .get(`${config.ROOT}profinance/reserveContractNo`)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}

export async function createOrder(orderData) {
  try {
    // Assuming orderData is a composite of products, form information, and customer information
    const requestBody = {
      id: orderData.id, // Ensure you have an ID in your orderData (this might come from a unique order ID)
      data: {
        productsValues: orderData.productsValues,
        formInformation: orderData.formInformation,
        customerInformation: orderData.customerInformation,
      },
    };

    const response = await axios.post(`${config.ROOT}order`, requestBody, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data; // Return the data from the server
  } catch (error) {
    console.error("Error creating order:", error);
    throw new Error("Failed to create order");
  }
}

/*export async function getContractNumber(
  productsValues,
  formInformation,
  customerInformation
) {
  const body = { productsValues, formInformation, customerInformation };
  return await axios
    .post(`${config.ROOT}profinance/create-contract-no`, body)
    .then((response) => response.data)
    .catch((error) => console.log("ERROR:", error));
}*/

/**
 *
 * @param {File} orderPDF
 * @param {File[]} extraFiles
 * @param {Object} info
 * @returns Server response
 *
 * POST files to server and return response.
 */
export async function onSubmitDB(files, info, formInfo) {
  /* body will contain all input answers from the webform */
  let body = {};
  /* Append answers */
  body = appendDictToFormData(body, formInfo, info["formType"]);
  /* const uploadDb = async () => {
    // Create a new Form a.k.a new order in database, form.data will be id of newly created form
    const form = await axios.post(
      `${config.ROOT}form/create/${info.formType}/${info.lang}/${info.lang}`
    );
    await axios.post(`${config.ROOT}answers/create/${form.data}`, body);
  }; */
  const sendEmail = async () => {
    body["lang"] = info["lang"];
    body["type"] = info["formType"];
    body["comp"] = info["comp"];
    body["files"] = files;
    return await axios
      .post(`${config.ROOT}email/sendEmail`, body)
      .then((response) => response.data)
      .catch((error) => console.log("ERROR:", error));
  };

  const sendConfirmationAndConfidentialityEmail = async () => {
    const socialSecurityNumberCountries = ["se", "dk", "no", "fi"];
    const products = {};
    let accessoryId = Object.keys(formInfo.productsValues).length + 1;

    for (const key in formInfo.productsValues) {
      if (formInfo.productsValues.hasOwnProperty(key)) {
        const product = formInfo.productsValues[key];

        // Create a new product in products object
        products[key] = {
          name: product.name,
          deliveryDate:
            product.deliveryDate === null
              ? "-"
              : dateToString(product.deliveryDate),
          serial: product.serial.length > 0 ? product.serial[0] : "-",
        };

        // Iterate over accessories
        for (let i = 0; i < product.accessory.length; i++) {
          const accessory = product.accessory[i];
          if (accessory !== null) {
            const accessoryKey = "accessory" + accessoryId;
            products[accessoryKey] = {
              name: accessory,
              deliveryDate:
                product.deliveryDate === null
                  ? "-"
                  : dateToString(product.deliveryDate),
              serial: "-",
            };
            accessoryId = accessoryId + 1;
          }
        }
      }
    }

    let emailBody = {
      lang: info.lang,
      type: info.formType,
      comp: info.comp,
      recipient: "seller",
      dealerName: formInfo.customerInformation.dealerName,
      dealerEmail: formInfo.customerInformation.dealerEmail,
      customerFirstName: formInfo.customerInformation.customerFirstName,
      customerLastName: formInfo.customerInformation.customerLastName,
      customerEmail: formInfo.customerInformation.customerEmail,
      customerPhone: formInfo.customerInformation.customerPhone,
      customerStreet: formInfo.customerInformation.customerStreet,
      customerHouseNumber: formInfo.customerInformation.customerHouseNumber,
      customerZipCode: formInfo.customerInformation.customerZipCode,
      customerCity: formInfo.customerInformation.customerCity,
      contractLength: formInfo.formInformation.contractLength,
      monthlyFee: formInfo.formInformation.totalMonthlyFee,
      socialSecurityNumber: socialSecurityNumberCountries.includes(info.lang)
        ? formInfo.customerInformation.socialSecurityNumber
        : dateToString(formInfo.customerInformation.dateOfBirth),
      date: dateToString(new Date()),
      products: products,
      labels: formInfo.labels,
    };

    await axios
      .post(`${config.ROOT}email/sendOrderConfirmation`, emailBody)
      .then((response) => response.data)
      .catch((error) => console.log("ERROR:", error));

    emailBody["recipient"] = "customer";

    await axios
      .post(`${config.ROOT}email/sendOrderConfirmation`, emailBody)
      .then((response) => response.data)
      .catch((error) => console.log("ERROR:", error));

    return "Success!";
  };

  // Upload information to DataBase:
  //uploadDb();

  //add countries that should send confirmation mail o
  const resp = await sendEmail();
  if (resp.status === 200 && info.lang === "dk") {
    await sendConfirmationAndConfidentialityEmail();
  }
  return resp;
}

export async function arjoTos() {
  return await axios
    .get(config.ROOT + "arjoTos")
    .then((response) => response)
    .catch((error) => error);
}

export function fetchAddressSuggestions(input) {
  return axios
    .get(`${config.ROOT}address/suggestions`, {
      params: { input },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching address suggestions:", error);
      throw error;
    });
}

export function fetchPlaceDetails(placeId) {
  return axios
    .get(`${config.ROOT}address/place`, {
      params: { PlaceID: placeId },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching place details:", error);
      throw error;
    });
}

export function uploadInvoiceForChecking(file, first_name, last_name, address) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("first_name", first_name);
  formData.append("last_name", last_name);
  formData.append("address", address);

  return axios
    .post(`${config.ROOT}invoice-checker/aws_textract`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data.result)
    .catch((error) => {
      console.error("Error uploading invoice for checking:", error);
      throw error;
    });
}
