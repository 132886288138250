import React from "react";
import UpCareProduct from "./Forms/UpCare";
import FlexPrivateProduct from "./Forms/FlexPrivate";
import FlexProProduct from "./Forms/FlexPro";
import LeasePlusProduct from "./Forms/LeasePlus";
import Information from "../component/Information";
import { AuthContext } from "../auth/hcsauthprovider";

import AccessoriesConstruction from "../component/Accessories";
import { sendOrder } from "../component/sendOrder";

import { calculateTotalFee } from "../logic/Calculations";
import { useParams } from "react-router-dom";
import DisplayField from "../component/Fields/DisplayField";
import debounce from "lodash.debounce";
import CreditCheck from "../component/CreditCheck/CreditCheck";
import CreateContract from "../component/Contract/CreateContract";
import DisplayContract from "../component/Contract/DisplayContract";
import FormRes from "../component/FormRes/FormRes";
import CircularProgress from "@mui/material/CircularProgress";
import InvoiceModal from "../component/Information/Customer/InvoiceModal";
import {
  getCountryInformationDB,
  getCountryInformation,
  passwordCheck,
  getDefaultPrices,
  verifiedRequest,
  getContractPDF,
  getDefaultPricesAccessories,
  getAccessories,
  getContractNumber,
  uploadInvoiceForChecking,
  createOrder,
} from "../logic/ServerCalls";

import {
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
  TextField,
  Divider,
  FormHelperText,
  Typography,
  Box,
  Modal,
  darken,
} from "@mui/material";
import CustomerInformationBusiness from "../component/Information/Customer/Business";
import CustomerInformationPrivate from "../component/Information/Customer/Private";
import CustomerInformationIdentification from "../component/Information/Customer/Identification";
import QuoteCustomerInformation from "../component/Information/Customer/QuoteInformation";
import FillBySSN from "../component/Information/Customer/FillBySSN";
import DealerInformation from "../component/Information/Dealer/DealerInformation";
import NewDealer from "../component/Information/Dealer/NewDealer";
import Header from "../component/Header";
import ConfidentialityNotice from "../component/ConfidentialityNotice";

import ConstructionProduct from "./Forms/Construction";
import NotFound from "../component/NotFound";
import configSource from "../res/config.json";
import config from "./res/config.json";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import LoginIcon from "@mui/icons-material/Login";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import StepperComponent from "../component/StepperComponent";
import "./css/Hcs.css";

const ReactDomServer = require("react-dom/server");

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

const socialSecurityNumberCountries = ["se", "dk", "no", "fi"];

class Hcs extends React.Component {
  state = {
    labels: {},
    allLabels: {},
    variables: {},
    products: {},
    headerText: "",
    headerImgSrc: "",
    params: {},
    beLanguage: "fr",
    modalOpen2: true,
    productsValues: {
      product1: {
        deliveryDate: null,
        productList: "productList1",
        name: null,
        quantity: 1,
        serial: [],
        price: 0,
        service: 0,
        purchasePrice: 0,
        installation: 0,
        residual: 10,
        residualPrice: 0,
        accessory: [null],
        accessoryPrice: [0],
        purchasePriceAccessory: [0],
        installationAccessory: [0],
        residualPriceAccessory: [0],
        residualPriceAccessorySum: 0,
        purchasePriceAccessorySum: 0,
        installationAccessorySum: 0,
        upCare: false,
        firstIncresedRent: 0,
        totalProductPrice: 0,
        finished: false,
        ownServiceIncludes: {
          storage: false,
          consumables: false,
          education: false,
          maintenance: false,
          repairs: true,
          other: false,
          noService: false,
        },
        manuallyModified: {
          installationModified: false,
        },
      },
    },
    accessories: {},
    customerInformation: {
      privateFinished: false,
      businessFinished: false,
      quoteInformationFinished: false,
      identificationFinished: false,
      dealerFinished: false,
      dealerName: "",
      dealerEmail: "",
      dealerPhone: "",
      dealerAdress: "",
      dealerZipCode: "",
      dealerCity: "",
      customerFirstName: "",
      customerLastName: "",
      customerStreet: "",
      customerHouseNumber: "",
      customerEmail: "",
      customerPhone: "",
      customerNumber: "",
      customerCity: "",
      customerZipCode: "",
      idType: "",
      idNumber: "",
      pep: "",
      repurchase: "",
      dateOfBirth: null,
      socialSecurityNumber: "",
      address: "",
      zipCode: "",
      city: "",
      signatories: {
        signatory1: {
          nameSignatory: "",
          emailSignatory: "",
          phoneSignatory: "",
          socialSecurityNumberCustomerSignatory: "",
          dateOfBirthCustomerSignatory: null,
          idTypeSignatory: "",
          idNumberSignatory: "",
          pepSignatory: "",
        },
      },
    },
    formInformation: {
      totalMonthlyFee: 0,
      totalPrice: 0,
      totalResidue: 0,
      totalAccessoriesPrice: 0,
      amortization: 0,
      formState: "edit",
      showInvalid: false,
      quoteChecked: false,
      orderChecked: false,
      newDealerChecked: false,
      newDealerNotChecked: false,
      idChecked: false,
      ampChecked: false,
      ceoraCampaign: false,
      contractLength: 36,
      contractLengths: [36],
      customer: "Private",
      useCampaign: false,
      extraInfo: "",
      contractNumber: "",
      fillBySSN: false,
    },
    newDealerInformation: {
      newDealerFinished: false,
      verifyNewDealer: false,
      newDealerName: "",
      taxNumber: "",
      organizationNumber: "",
      husqvarnaNumber: "",
      fcaNumber: "",
      dealerAddress: "",
      nd_zipCode: "",
      nd_city: "",
      invoiceEmail: "",
      contactPerson: "",
      emailContactPerson: "",
      phoneContactPerson: "",
      bankName: "",
      ibanNumber: "",
      bicSwiftCode: "",
      bankNumber: "",
      sortCode: "",
      registrationNumber: "",
      newDealerSignatories: {
        newDealerSignatory1: {
          nameSignatoryNewDealer: undefined,
          emailSignatoryNewDealer: undefined,
          dateOfBirthSignatory: null,
          socialSecurityNumberSignatory: undefined,
        },
      },
    },
    creditInfo: {
      score: null,
    },
    contractFile: {
      file: null,
      fileName: "Hyreskontrakt_Lease_Plus.pdf",
      fileDisplay: null,
      fileSize: null,
    },
    creditFile: {
      file: null,
      fileName: "credit.pdf",
      fileSize: null,
    },
    campaign: false,
    extraFiles: [],
    firstRender: true,
    isVerified: false,
    showAdminLogin: false,
    passwordInput: "",
    stepLabels: [],
    stepCounter: 0,
    isLoading: false,
    invoiceDidNotMatch: false,
  };

  static contextType = AuthContext;
  /**
   *  Gets called after first render.
   */
  componentDidMount() {
    if (this.context.isAuthenticated()) {
      this.setState({ isVerified: true });
    }
    if (this.validateParams()) {
      this.setState({
        firstRender: false,
        params: { ...this.props.params, comp: "hcs" },
      });
      this.SetFormInformation();
    }
  }

  handleInvoiceModalClose = async (matched) => {
    await new Promise((resolve) =>
      this.setState({ invoiceDidNotMatch: false }, resolve)
    );

    if (matched) {
      this.switchFormState("review");
    }
  };

  /**
   * Requests labels, headerText, productlist and variables from server and puts them in state.
   */
  SetFormInformation = () => {
    (async () => {
      try {
        const comp = "hcs";
        const lang = this.props.params.lang.toLowerCase();
        const formType = this.props.params.formType.toLowerCase();
        let data = await getCountryInformationDB(comp, lang, formType);
        const regex = /^step\d+$/;

        let state = this.state;
        if (
          formType === "upcare" ||
          formType === "flexpro" ||
          formType === "construction" ||
          formType === "standard"
        ) {
          state.formInformation.customer = "Business";
        } else {
          state.formInformation.customer = "Private";
        }
        let labels = {};
        for (const label of data.labelsResponse.data) {
          labels[label.Field.name] = label.value;
        }
        state.labels = labels;

        let i = 1;
        const sortedSteps = [];

        while (labels.hasOwnProperty(`step${i}`)) {
          if (regex.test(`step${i}`)) {
            sortedSteps.push(labels[`step${i}`]);
          }
          i++;
        }
        // if not social security then remove creditcheck
        if (!socialSecurityNumberCountries.includes(lang)) {
          sortedSteps.splice(2, 1);
        }
        state.stepLabels = sortedSteps;

        let tooltips = {};
        for (const tooltip of data.tooltipsResponse.data) {
          tooltips[tooltip.Field.name] = tooltip.value;
        }
        state.tooltips = tooltips;

        let variables = {};
        for (const values of data.variableResponse.data.Variables) {
          variables[values.name] = values.CountryVariable.value;
        }
        state.variables = variables;

        let products = [];
        for (const product of data.productsResponse.data) {
          products.push(product.name);
        }
        state.products = products;

        let accessories = [];
        for (const accessory of data.accessoriesResponse.data) {
          accessories.push(accessory.name);
        }
        state.accessories = accessories;

        let headertext = {
          text: data.headerTextResponse.data.text,
          campaign: data.headerTextResponse.data.campaign,
        };
        state.headerText = headertext;
        state.campaign = false;
        state.formInformation.contractLengths = data.contractLengths;
        state.formInformation.contractLength = Math.max(
          ...data.contractLengths
        );
        try {
          let response = await fetch(
            configSource.ROOT +
              `getimage?company=hcs&country=images&type=Logo.jpg`
          );
          state.headerImgSrc = response.url;
        } catch (error) {
          console.log("Could Not Fetch Header Image: \n", error);
        }
        this.setState(state);
      } catch (error) {
        //TODO redirect
        console.log(error);
      }
    })();
  };

  /**
   * Passes values to function and sets state with result
   */
  newCalculationsProductFee = debounce(() => {
    const result = calculateTotalFee(
      this.state.productsValues,
      this.state.formInformation.contractLength,
      this.state.variables,
      this.state.accessories,
      this.state.params.formType.toLowerCase()
    );
    if (this.state.formInformation.ceoraCampaign) {
      let discount = 1;
      let productList;
      if (this.state.formInformation.contractLength === 36) {
        productList = this.state.products.productList1;
      } else if (this.state.formInformation.contractLength === 48) {
        productList = this.state.products.productList2;
      } else if (this.state.formInformation.contractLength === 60) {
        productList = this.state.products.productList3;
      } else {
        productList = this.state.products.productList1;
      }
      for (const [, value] of Object.entries(this.state.productsValues)) {
        if (productList[value.name]?.campaign <= 1) {
          if (productList[value.name]?.campaign > discount) {
            discount = productList[value.name]?.campaign;
          } else if (discount === 1) {
            discount = productList[value.name]?.campaign;
          }
        }
      }
      result.totalMonthlyFee = result.totalMonthlyFee * discount;
    }
    let formInformation = this.state.formInformation;
    Object.assign(formInformation, result);
    this.setState({ formInformation: formInformation });
  }, 500);

  /**
   *
   * @param {Object} value - Updated product information
   * @param {String} id    - Product id
   *
   * Updates given product with information from value
   */
  updateProduct = (value, id) => {
    let productsValues = this.state.productsValues;
    let variables = this.state.variables;
    for (let key in value) {
      productsValues[id][key] =
        key === "ownServiceIncludes"
          ? Object.assign(productsValues[id][key], value[key])
          : (productsValues[id][key] = value[key]);
      if (key === "name") {
        productsValues[id].manuallyModified.installationModified = false;
      }
      if (key === "installation") {
        productsValues[id].manuallyModified.installationModified = true;
      }
    }
    this.setState({
      productsValues: productsValues,
    });
    if ("interest" in value) {
      variables["function_interest"] = value["interest"];
      this.setState({
        variables: variables,
      });
    }
  };

  /**
   * Used by LeasePlus and UpCare.
   * Calculates total monthly fee. Updates price, totalProductPrice and totalMonthlyFee.
   */
  updateTotalMonthlyFee = async () => {
    let formInformation = this.state.formInformation;
    let totalMonthlyFee = 0;
    let discount = 1;
    for (const productId in this.state.productsValues) {
      const nrOfAccessories =
        this.state.productsValues[productId].accessory.length;
      let product = this.state.productsValues[productId];
      let data = {};
      let price = 0;
      let residual = 0;
      let accessoryPrice = new Array(nrOfAccessories).fill(0);
      let purchasePrice = 0;
      let residualPrice = 0;
      let installation = 0;
      let purchasePriceAccessory = new Array(nrOfAccessories).fill(0);
      let residualPriceAccessory = new Array(nrOfAccessories).fill(0);
      let installationAccessory = new Array(nrOfAccessories).fill(0);

      if (this.state.formInformation.ceoraCampaign) {
        let campaign =
          this.state.products[this.state.productsValues[productId].productList][
            product.name
          ]?.campaign;
        if (campaign > discount || discount === 1) {
          discount = campaign;
        }
      }
      if (product.name !== null) {
        const defaultPrices = await getDefaultPrices(
          this.state.params.lang.toLowerCase(),
          this.state.params.formType.toLowerCase(),
          product.name,
          this.state.formInformation.contractLength
        );
        price = defaultPrices?.DefaultProductValue.monthlyFee;
        installation =
          defaultPrices?.DefaultProductValue.ServicePrices[0].price;
        residualPrice = defaultPrices?.DefaultProductValue.residual;
        purchasePrice = defaultPrices?.DefaultProductValue.price;
      }

      for (const accessoryIndex in this.state.productsValues[productId]
        .accessory) {
        const accessory =
          this.state.productsValues[productId].accessory[accessoryIndex];
        if (accessory !== null) {
          let productName = "";
          if (product.name === null) {
            for (let productTest of this.state.products) {
              const accessories = await getAccessories(
                this.props.params.lang.toLowerCase(),
                this.props.params.formType.toLowerCase(),
                this.state.formInformation.contractLength,
                productTest
              );
              const accessoryArray = accessories.map((object) => object.name);
              if (accessoryArray.includes(accessory)) {
                productName = productTest;
                break;
              }
            }
          } else {
            productName = product.name;
          }
          const defaultPricesAccessories = await getDefaultPricesAccessories(
            this.state.params.lang.toLowerCase(),
            this.state.params.formType.toLowerCase(),
            productName,
            this.state.formInformation.contractLength,
            accessory
          );
          accessoryPrice[accessoryIndex] +=
            defaultPricesAccessories?.DefaultProductValue.monthlyFee;
          installationAccessory[accessoryIndex] +=
            defaultPricesAccessories?.DefaultProductValue.ServicePrices[0].price;
          residualPriceAccessory[accessoryIndex] +=
            defaultPricesAccessories?.DefaultProductValue.residual;
          purchasePriceAccessory[accessoryIndex] +=
            defaultPricesAccessories?.DefaultProductValue.price;
        }
      }

      const accessoryPriceSum = accessoryPrice.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const installationAccessorySum = installationAccessory.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const residualPriceAccessorySum = residualPriceAccessory.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const purchasePriceAccessorySum = purchasePriceAccessory.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      let totalProductPrice = price * product.quantity + accessoryPriceSum;
      data["residualPrice"] = residualPrice;
      data["purchasePrice"] = purchasePrice;
      data["installation"] = installation;
      data["residualPriceAccessory"] = residualPriceAccessory;
      data["purchasePriceAccessory"] = purchasePriceAccessory;
      data["installationAccessory"] = installationAccessory;
      data["residualPriceAccessorySum"] = residualPriceAccessorySum;
      data["purchasePriceAccessorySum"] = purchasePriceAccessorySum;
      data["installationAccessorySum"] = installationAccessorySum;
      data["price"] = price;
      data["totalProductPrice"] = totalProductPrice;
      data["residual"] = residual;
      data["accessoryPrice"] = accessoryPrice;
      totalMonthlyFee += totalProductPrice;
      this.updateProduct(data, productId);
    }
    let accessoryPriceExtra = 0;
    let annuityAccessory = 0;
    if (Object.keys(this.state.accessories).length > 0) {
      let accessories = this.state.accessories;
      Object.keys(this.state.accessories).forEach((accessory) => {
        if (accessories[accessory].TotalAccessoriesPrice !== undefined) {
          accessoryPriceExtra += accessories[accessory]?.TotalAccessoriesPrice;
        }
      });
      let t =
        (1 + this.state.variables.function_interest / 1200) **
        this.state.formInformation.contractLength;
      annuityAccessory =
        (accessoryPriceExtra *
          t *
          (this.state.variables.function_interest / 1200)) /
        ((1 +
          (this.state.variables.function_interest / 1200) *
            this.state.variables.payment) *
          (t - 1));
      accessoryPriceExtra = annuityAccessory;
    }
    totalMonthlyFee += accessoryPriceExtra;
    if (this.state.formInformation.ceoraCampaign) {
      totalMonthlyFee = totalMonthlyFee * discount;
    }
    formInformation.totalMonthlyFee = totalMonthlyFee;
    this.setState({
      formInformation: formInformation,
    });
  };

  /**
   *
   * @param {Array<File>} files
   *
   * Puts the files in the array in state.
   */
  addExtraFiles = (files) => {
    let extraFiles = this.state.extraFiles;
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      extraFiles.push(element);
    }
    this.setState({ extraFiles: extraFiles });
  };

  /**
   *
   * @param {File} file
   *
   * Removes given file from state if it exists.
   */
  removeExtraFiles = (file) => {
    let extraFiles = this.state.extraFiles;
    let index = extraFiles.indexOf(file);
    if (index !== -1) {
      extraFiles.splice(index, 1);
    }
    this.setState({ extraFiles: extraFiles });
  };

  /**
   *
   * Adds new object in productsValues with newId.
   * newId = highest oldId + 1.
   * Also populates the new product objects with deafult values.
   */
  addAnotherProduct = () => {
    const newId = Object.keys(this.state.productsValues).length + 1;

    const key = "product" + newId;

    let productsValues = this.state.productsValues;
    productsValues[key] = {};
    productsValues[key]["deliveryDate"] = null;
    productsValues[key]["productList"] = "productList1";
    productsValues[key]["name"] = null;
    productsValues[key]["quantity"] = 1;
    productsValues[key]["serial"] = [];
    productsValues[key]["price"] = 0;
    productsValues[key]["accessory"] = [null];
    productsValues[key]["accessoryPrice"] = [0];
    productsValues[key]["service"] = 0;
    productsValues[key]["purchasePrice"] = 0;
    productsValues[key]["residualPrice"] = 0;
    productsValues[key]["installation"] = 0;
    productsValues[key]["residual"] = 10;
    productsValues[key]["upCare"] = false;
    productsValues[key]["firstIncresedRent"] = 0;
    productsValues[key]["totalProductPrice"] = 0;
    productsValues[key]["finished"] = false;
    productsValues[key]["purchasePriceAccessory"] = [0];
    productsValues[key]["installationAccessory"] = [0];
    productsValues[key]["residualPriceAccessory"] = [0];
    productsValues[key]["residualPriceAccessorySum"] = 0;
    productsValues[key]["purchasePriceAccessorySum"] = 0;
    productsValues[key]["installationAccessorySum"] = 0;
    productsValues[key]["ownServiceIncludes"] = {
      storage: false,
      consumables: false,
      education: false,
      maintenance: false,
      repairs: true,
      other: false,
    };
    productsValues[key]["manuallyModified"] = {
      installationModified: false,
    };

    this.setState({
      productsValues: productsValues,
    });
  };

  /**
   * Adds a new leaseplus accessory
   */
  addLeasePlusAccessory = (productId) => {
    let productsValues = this.state.productsValues;
    productsValues[productId].accessory.push(null);
    productsValues[productId].purchasePriceAccessory.push(0);
    productsValues[productId].accessoryPrice.push(0);
    productsValues[productId].installationAccessory.push(0);
    productsValues[productId].residualPriceAccessory.push(0);
    this.setState({
      productsValues: productsValues,
    });
  };

  /**
   * Removes last leaseplus accessory.
   */
  removeLeasePlusAccessory = (productId) => {
    let productsValues = this.state.productsValues;
    productsValues[productId].accessory.pop();
    productsValues[productId].purchasePriceAccessory.pop();
    productsValues[productId].accessoryPrice.pop();
    productsValues[productId].installationAccessory.pop();
    productsValues[productId].residualPriceAccessory.pop();
    this.setState({
      productsValues: productsValues,
    });
    this.updateTotalMonthlyFee();
  };

  /**
   *
   * Removes the last product from state if there are more than one
   * and updates totalMonthlyFee in state.
   */
  removeProduct = () => {
    let productsValues = this.state.productsValues;
    const id = Object.keys(productsValues).at(-1);
    delete productsValues[id];
    this.setState({
      productsValues: productsValues,
    });
    if (
      this.state.params.formType.toLowerCase() === "upcare" ||
      this.state.params.formType.toLowerCase() === "leaseplus"
    ) {
      this.updateTotalMonthlyFee();
    } else {
      this.newCalculationsProductFee();
    }
  };

  /**
   * Adds a new Object to accessories.
   */
  addAccessories = () => {
    const key = Object.keys(this.state.accessories).length + 1;
    const id = "accessories" + key;
    let accessoreis = this.state.accessories;
    accessoreis[id] = {};
    this.setState({
      accessoreis: accessoreis,
    });
  };

  /**
   * Removes last object from accessories.
   */
  removeAccessories = () => {
    const id = Object.keys(this.state.accessories).at(-1);
    let accessoreis = this.state.accessories;
    delete accessoreis[id];
    this.setState({
      accessoreis: accessoreis,
    });
    if (
      this.state.params.formType.toLowerCase() === "upcare" ||
      this.state.params.formType.toLowerCase() === "leaseplus"
    ) {
      this.updateTotalMonthlyFee();
    } else {
      this.newCalculationsProductFee();
    }
  };

  /**
   * Adds a new object to signatories.
   */
  addSignatory = (newDealer) => {
    if (newDealer) {
      const key =
        Object.keys(this.state.newDealerInformation.newDealerSignatories)
          .length + 1;
      const id = "newDealerSignatory" + key;
      let signatories = this.state.newDealerInformation.newDealerSignatories;
      signatories[id] = {
        nameSignatoryNewDealer: "",
        emailSignatoryNewDealer: "",
        dateOfBirthSignatory: null,
        socialSecurityNumberSignatory: "",
      };
      this.setState({
        newDealerSignatories: signatories,
      });
    } else {
      const key =
        Object.keys(this.state.customerInformation.signatories).length + 1;
      const id = "signatory" + key;
      let signatories = this.state.customerInformation.signatories;
      signatories[id] = {
        nameSignatory: "",
        emailSignatory: "",
        phoneSignatory: "",
        socialSecurityNumberCustomerSignatory: "",
        dateOfBirthCustomerSignatory: null,
        idTypeSignatory: this.state.labels.id_select[0],
        idNumberSignatory: "",
        pepSignatory: this.state.labels.pep[0],
      };
      this.setState({
        signatories: signatories,
      });
    }
  };

  /**
   * Remove last object from signatories.
   */
  removeSignatory = (newDealer) => {
    if (newDealer) {
      const id = Object.keys(
        this.state.newDealerInformation.newDealerSignatories
      ).at(-1);
      let signatories = this.state.newDealerInformation.newDealerSignatories;
      delete signatories[id];
      this.setState({
        newDealerSignatories: signatories,
      });
    } else {
      const id = Object.keys(this.state.customerInformation.signatories).at(-1);
      let signatories = this.state.customerInformation.signatories;
      delete signatories[id];
      this.setState({
        signatories: signatories,
      });
    }
  };

  generateAccessories = (id) => {
    return (
      <AccessoriesConstruction
        id={id}
        key={id}
        labels={this.state.labels}
        handleAccessories={this.handleAccessories}
        accessories={this.state.accessories[id]}
        number={id.charAt(id.length - 1)}
      />
    );
  };

  /**
   *
   * @param {Object} info
   *
   * Updates the value of customerInformation based on keys in info.
   */
  handleCustomerInfoChange = (info) => {
    let customerInformation = this.state.customerInformation;
    for (let id in info) {
      customerInformation[id] = info[id];
    }
    this.setState({
      customerInformation: customerInformation,
    });
  };

  /**
   *
   * @param {Object} info
   *
   * Updates the value of newDealerInformation based on keys in info.
   */
  handleNewDealerInfoChange = (info) => {
    let newDealerInformation = this.state.newDealerInformation;
    for (let id in info) {
      newDealerInformation[id] = info[id];
    }
    this.setState({
      newDealerInformation: newDealerInformation,
    });
  };

  /**
   *
   * @param {Object} value
   * @param {String} id
   *
   * Updates the value of the accessory with key id with the new value.
   */
  handleAccessories = (value, id) => {
    let accessories = this.state.accessories;

    Object.entries(value).forEach(([key, val]) => {
      accessories[id][key] = val;
    });
    this.setState(accessories);
    if (this.state.params.formType.toLowerCase() === "upcare") {
      this.updateTotalMonthlyFee();
    } else {
      this.newCalculationsProductFee();
    }
  };

  handleFormInformationChange = (info) => {
    let formInformation = this.state.formInformation;
    for (let id in info) {
      formInformation[id] = info[id];
    }
    this.setState({
      formInformation: formInformation,
    });
  };

  handleCreditInfoChange = (info) => {
    let creditInfo = this.state.creditInfo;
    for (let id in info) {
      creditInfo[id] = info[id];
    }
    this.setState({
      creditInfo: creditInfo,
    });
  };

  handleContractFileChange = (info) => {
    let contractFile = this.state.contractFile;
    for (let id in info) {
      contractFile[id] = info[id];
    }
    this.setState({
      contractFile: contractFile,
    });
  };

  handleCreditFileChange = (info) => {
    let creditFile = this.state.creditFile;
    for (let id in info) {
      creditFile[id] = info[id];
    }
    this.setState({
      creditFile: creditFile,
    });
  };

  /**
   *
   * @param {Object} event
   *
   * Handles checkbox check depending on case.
   */
  handleChecked = async (event) => {
    let newState;
    let newCustomerState;
    let formInformation = this.state.formInformation;
    let newDealerInformation = this.state.newDealerInformation;
    switch (event.target.id) {
      case "quote_check":
        newState = this.state.formInformation.quoteChecked ? false : true;
        if (newState && this.state.formInformation.orderChecked) {
          formInformation.orderChecked = false;
        }
        formInformation.quoteChecked = newState;
        this.setState({ formInformation: formInformation });
        break;
      case "order_check":
        newCustomerState = this.state.customerInformation;
        newState = this.state.formInformation.orderChecked ? false : true;
        if (!newState) {
          formInformation.idChecked = false;
        }
        if (this.state.params.formType.toLowerCase() === "at") {
          newCustomerState["pep"] = this.state.labels.pep[0];
          newCustomerState["idType"] = this.state.labels.id_select[0];
        }
        newCustomerState["idNumber"] = "";
        newCustomerState["repurchase"] = this.state.labels.dealer_repurchase;
        newCustomerState.signatories.signatory1.idTypeSignatory = "";
        newCustomerState.signatories.signatory1.pepSignatory = "";
        if (newState && this.state.formInformation.quoteChecked) {
          formInformation.quoteChecked = false;
        }
        if (
          this.state.formInformation.customer === "Private" &&
          socialSecurityNumberCountries.includes(
            this.state.params.lang.toLowerCase()
          )
        ) {
          formInformation.fillBySSN = true;
        }
        formInformation.orderChecked = newState;
        this.setState({
          formInformation: formInformation,
          customerInformation: newCustomerState,
        });
        break;
      case "id_checked":
        newState = this.state.formInformation.idChecked ? false : true;
        formInformation.idChecked = newState;
        this.setState({ formInformation: formInformation });
        break;
      case "new_dealer_yes":
        formInformation.newDealerChecked = true;
        formInformation.newDealerNotChecked = false;
        this.setState({ formInformation: formInformation });
        break;
      case "new_dealer_no":
        formInformation.newDealerNotChecked = true;
        formInformation.newDealerChecked = false;
        this.setState({ formInformation: formInformation });
        break;
      case "verify_new_dealer":
        newState = this.state.newDealerInformation.verifyNewDealer
          ? false
          : true;
        newDealerInformation.verifyNewDealer = newState;
        this.setState({ newDealerInformation: newDealerInformation });
        break;
      case "amp_checked":
        newState = this.state.formInformation.ampChecked ? false : true;
        formInformation.ampChecked = newState;
        this.setState({ formInformation: formInformation });
        break;
      case "ceora_campaign":
        if (
          this.state.formInformation.ceoraCampaign &&
          this.state.formInformation.contractLengths.length < 2
        ) {
          let data = await getCountryInformation(
            "hcs",
            this.state.params.lang.toLowerCase(),
            this.state.params.formType.toLowerCase()
          );
          data = data.data;
          formInformation.contractLengths = data["Products"]["contractLengths"];
        } else {
          formInformation.contractLength = 60;
        }
        let data = {};
        data["name"] = null;
        data["service"] = 0;
        data["upCare"] = false;
        for (let product in this.state.productsValues) {
          this.updateProduct(data, product);
        }
        if (this.state.params.formType === "flexpro") {
          this.newCalculationsProductFee();
        } else {
          this.updateTotalMonthlyFee();
        }
        newState = this.state.formInformation.ceoraCampaign ? false : true;
        formInformation.ceoraCampaign = newState;
        this.setState({ formInformation: formInformation });
        break;
      default:
        throw Error("Unknown button ID");
    }
  };

  /**
   *
   * @param {Object} event
   *
   * Handles input in password box.
   */

  handleInput = (event) => {
    let id = event.target.id;
    let data = {};
    if (id === "password_input") {
      data["passwordInput"] = event.target.value;
    }
    this.setState(data);
  };

  handleSelectChange = (event) => {
    let value = event.target.value;
    let state = this.state;
    switch (value) {
      case "fr":
        state.labels = this.state.allLabels.fr;
        state.beLanguage = "fr";
        this.setState(state);
        break;
      case "nl":
        state.labels = this.state.allLabels.nl;
        state.beLanguage = "nl";
        this.setState(state);
        break;
      default:
        throw Error("Unknwon ID");
    }
  };

  handleClick = async (event) => {
    let id = event.target.id;
    if (id === "password_check") {
      if (await passwordCheck(this.state.passwordInput)) {
        this.setState({ isVerified: true });
      } else {
        alert("Wrong password.");
        let event = { target: { id: "password_input", value: "" } };
        this.handleInput(event);
      }
    }
  };

  /**
   *
   * @param {String} value
   *
   * Switches the current formState to new value.
   */
  switchFormState = (value) => {
    let state = this.state;
    state.formInformation.formState = value;

    if (value === "edit") {
      state.stepCounter = 0;
    } else if (value === "confidentiality_notice") {
      state.stepCounter = 1;
    } else if (value === "credit_check") {
      state.stepCounter = 2;
    } else if (value === "create_contract") {
      state.stepCounter = 3;
    } else if (value === "completed") {
      state.stepCounter = 4;
    }

    this.setState(state);
  };

  /**
   *
   * @param {Object} event
   *
   * Updates the contractLenght in state to new value, calls for update of totalMonthlyFee.
   */
  changeContractLength = (event) => {
    let state = this.state;
    state.formInformation.contractLength = event.target.value;
    this.setState(state);
    if (this.state.params.formType.toLowerCase() === "flexpro") {
      let data = {};
      data["name"] = null;
      data["service"] = 0;
      data["upCare"] = false;
      for (let product in this.state.productsValues) {
        this.updateProduct(data, product);
      }
    }
    if (this.state.params.formType.toLowerCase() === "upcare") {
      let index = this.state.formInformation.contractLengths.indexOf(
        event.target.value
      );
      if (this.state.formInformation.ceoraCampaign) {
        index = this.state.formInformation.contractLengths.indexOf(60);
        state.formInformation.contractLength = 60;
      }
      for (let product in this.state.productsValues) {
        let data = {};
        const name = this.state.productsValues[product].name;
        if (name !== null) {
          if (this.state.products.productList1[name]?.price.length <= index) {
            data["name"] = null;
          }
        }
        this.updateProduct(data, product);
      }
    }
    if (this.state.params.formType.toLowerCase() === "construction") {
      let data = {};
      data["residual"] = 10;
      data["name"] = null;
      data["service"] = 0;
      for (let product in this.state.productsValues) {
        this.updateProduct(data, product);
      }
    }
    if (
      this.state.params.formType.toLowerCase() === "leaseplus" ||
      this.state.params.formType.toLowerCase() === "upcare"
    ) {
      this.updateTotalMonthlyFee();
    } else {
      this.newCalculationsProductFee();
    }
  };

  showInvalid = (newValue = true) => {
    let newFormInformation = this.state.formInformation;
    newFormInformation.showInvalid = newValue;
    this.setState({
      formInformation: newFormInformation,
    });
  };

  sendContract = async (info) => {
    //also add the orderinfo to db in backend
    await createOrder({
      id: this.state.formInformation.contractNumber,
      productsValues: this.state.productsValues,
      customerInformation: this.state.customerInformation,
      formInformation: this.state.formInformation,
    });

    let { language, signingMethod } = this.getSigningMethodAndCountryCode(
      this.state.params.lang.toLowerCase()
    );
    /* Create formdata and append all parameters for the request */
    const formData = new FormData();
    formData.append(
      "givenName",
      this.state.customerInformation.customerFirstName
    );
    formData.append(
      "familyName",
      this.state.customerInformation.customerLastName
    );
    formData.append("language", language);
    formData.append("email", this.state.customerInformation.customerEmail);
    formData.append("sms", true);
    formData.append("telephone", this.state.customerInformation.customerPhone);
    formData.append("name", this.state.contractFile.fileName);
    formData.append(
      "contractNumber",
      this.state.formInformation.contractNumber
    );
    formData.append("file", this.state.contractFile.file);
    formData.append("signingMethod", signingMethod);

    const verifiedResponse = await verifiedRequest(formData);
    return verifiedResponse;
  };

  /**
   *
   * @returns Boolean
   */
  validateFormInputs = () => {
    if (configSource["DEBUG"]) return true;
    if (this.state.formInformation.totalMonthlyFee < 0.1) {
      this.showInvalid();
      alert("All required fields are not filled in1");
      return false;
    }
    if (
      this.state.formInformation.orderChecked &&
      (!(
        (this.state.customerInformation.businessFinished ||
          this.state.customerInformation.privateFinished) &&
        this.state.customerInformation.identificationFinished &&
        this.state.customerInformation.dealerFinished
      ) ||
        !(
          !this.state.formInformation.newDealerChecked ||
          this.state.newDealerInformation.newDealerFinished
        ))
    ) {
      this.showInvalid();

      alert("All required fields are not filled in2");
      return false;
    }
    if (
      this.state.formInformation.quoteChecked &&
      !(
        this.state.customerInformation.quoteInformationFinished &&
        this.state.customerInformation.dealerFinished
      )
    ) {
      this.showInvalid();
      alert("All required fields are not filled in3");
      return false;
    }
    for (let product in this.state.productsValues) {
      if (
        this.state.productsValues[product]["finished"] === undefined ||
        this.state.productsValues[product]["finished"] === false
      ) {
        this.showInvalid();
        alert("All required fields are not filled in4");
        return false;
      }
    }
    if (
      this.state.params.lang === "at" &&
      this.state.extraFiles.length < 1 &&
      this.state.formInformation.orderChecked
    ) {
      this.showInvalid();
      alert(this.state.labels.must_attach);
      return false;
    }
    if (
      this.props.params.lang === "fr" &&
      this.props.params.formType === "leaseplus" &&
      this.state.extraFiles.length < 1 &&
      this.state.formInformation.orderChecked
    ) {
      this.showInvalid();
      alert(
        "La facture d'électricité ou de téléphone du client doit être téléchargée pour pouvoir envoyer la demande à HCS."
      );
      return false;
    }
    this.showInvalid(false);
    return true;
  };

  /**
   *
   * @returns Boolean
   *
   * Check the params to validate that the form requested exists.
   */
  validateParams = () => {
    const allowedArgs = config.ALLOWED_ARGS;
    if (
      Object.keys(allowedArgs["hcs"]).includes(
        this.props.params.lang.toLowerCase()
      ) &&
      allowedArgs["hcs"][this.props.params.lang.toLowerCase()].includes(
        this.props.params.formType.toLowerCase()
      )
    ) {
      return true;
    }
    return false;
  };

  /**
   *
   * @param {string} id
   * @returns ReactElement || null
   *
   * Creates a ReactElement depending on formType and returns it.
   * If unknown formType return null.
   */

  generateProduct = (id) => {
    const standardProps = {
      key: id,
      id: id,
      number: id.charAt(id.length - 1),
      productValues: this.state.productsValues[id],
      formInformation: this.state.formInformation,
      labels: this.state?.labels,
      variables: this.state?.variables,
      params: this.state.params,
      updateProduct: this.updateProduct,
    };
    const index = this.state.formInformation.contractLengths.indexOf(
      this.state.formInformation.contractLength
    );
    let productList;
    switch (this.state.params.formType.toLowerCase()) {
      case "upcare":
        let finalList = {};
        for (const list in this.state.products) {
          if (list.includes("productList")) {
            finalList[list] = {};
            for (const key in this.state.products[list]) {
              if (
                Array.isArray(this.state.products[list][key].price) &&
                this.state.products[list][key].price.length > index
              ) {
                finalList[list][key] = this.state.products[list][key];
              }
            }
          }
        }
        if (this.state.formInformation.ceoraCampaign) {
          let newProductList = {};
          for (const [key, value] of Object.entries(finalList)) {
            if (
              key.includes("productList") &&
              Object.keys(value).length !== 0
            ) {
              newProductList[key] = {};
              for (const [product, prices] of Object.entries(value)) {
                if (prices?.campaign <= 1) {
                  newProductList[key][product] = prices;
                }
              }
            } else if (
              key.includes("productList") &&
              Object.keys(value).length === 0
            ) {
              newProductList[key] = {};
            }
          }
          finalList = newProductList;
        }
        const upCareProps = {
          products: finalList,
          updateTotalMonthlyFee: this.updateTotalMonthlyFee,
          handleChecked: this.handleChecked,
        };
        return React.createElement(
          UpCareProduct,
          Object.assign(standardProps, upCareProps)
        );
      case "leaseplus":
        const leasePlusProps = {
          products: this.state.products,
          accessories: this.state.accessories,
          updateTotalMonthlyFee: this.updateTotalMonthlyFee,
          removeLeasePlusAccessory: this.removeLeasePlusAccessory,
          addLeasePlusAccessory: this.addLeasePlusAccessory,
        };
        return React.createElement(
          LeasePlusProduct,
          Object.assign(standardProps, leasePlusProps)
        );
      case "flexpro":
        let flexProProps;
        if (this.state.params.lang.toLowerCase() !== "nl") {
          if (index === 0) {
            productList = this.state.products.productList1;
          } else if (index === 1) {
            productList = this.state.products.productList2;
          } else if (index === 2) {
            productList = this.state.products.productList3;
          } else {
            productList = this.state.products.productList1;
          }
        } else {
          productList = this.state.products.productList1;
        }
        if (this.state.formInformation.ceoraCampaign) {
          let newProductList = {};
          for (const [key, value] of Object.entries(productList)) {
            if (value?.campaign <= 1) {
              newProductList[key] = value;
            }
          }
          productList = newProductList;
        }
        flexProProps = {
          products: productList,
          calculateNewFee: this.newCalculationsProductFee,
        };

        return React.createElement(
          FlexProProduct,
          Object.assign(standardProps, flexProProps)
        );
      case "flexprivate":
        const flexPrivateProps = {
          products: this.state.products.productList1,
          calculateNewFee: this.newCalculationsProductFee,
        };
        return React.createElement(
          FlexPrivateProduct,
          Object.assign(standardProps, flexPrivateProps)
        );
      case "construction":
        const constructionProps = {
          products: this.state.products,
          calculateNewFee: this.newCalculationsProductFee,
        };
        return React.createElement(
          ConstructionProduct,
          Object.assign(standardProps, constructionProps)
        );
      default:
        return null;
    }
  };

  handleDealerButtonClick = () => {
    this.context.signinRedirect();
  };

  getStringWithLinks = (text) => {
    if (text !== undefined) {
      const textWithClickableLinks = text.replace(
        /https?:\/\/[^\s]+/g,
        (url) =>
          `<a href="${url}" target="_blank" rel="noopener noreferrer"><b>${
            this.state.labels.here + "."
          }</b></a>`
      );
      return textWithClickableLinks;
    } else {
      return "";
    }
  };

  getSigningMethodAndCountryCode = (country) => {
    let language, signingMethod;
    switch (country) {
      case "at":
        language = "de-AT";
        signingMethod = "touch-sign";
        break;
      case "de":
        language = "de-DE";
        signingMethod = "touch-sign";
        break;
      case "dk":
        language = "da_DK";
        signingMethod = "nets-eident-dk";
        break;
      case "fi":
        language = "fi_FI";
        signingMethod = "touch-sign";
        break;
      case "fr":
        language = "fr-FR";
        signingMethod = "touch-sign";
        break;
      case "nl":
        language = "nl-NL";
        signingMethod = "touch-sign";
        break;
      case "no":
        language = "nb_NO";
        signingMethod = "bankid-no";
        break;
      case "se":
        language = "sv_SE";
        signingMethod = "bankid-se";
        break;
      case "uk":
        language = "en_EN";
        signingMethod = "touch-sign";
        break;
      default:
        language = "en_EN";
        signingMethod = "touch-sign";
        return;
    }
    return { language, signingMethod };
  };

  sendBeforeCerditCheck = async () => {
    await this.createContractPDf();
    const props = {
      creditFile: this.state.creditFile,
      contractFile: this.state.contractFile,
      labels: this.state.labels,
      params: this.state.params,
      switchFormState: this.switchFormState,
      formInformation: this.state.formInformation,
      productsValues: this.state.productsValues,
      customerInformation: this.state.customerInformation,
      accessories: this.state.accessories,
      extraFiles: this.state.extraFiles,
      products: this.state.products,
      variables: this.state.variables,
      newDealerInformation: this.state.newDealerInformation,
      headerImgSrc: this.state.headerImgSrc,
      apiError: "OBS!! Kreditupplysningen funkade inte eller gick inte igenom!",
    };

    const serverResponce = (res) => {
      if (res.status === 200) {
        //if the mail was successfuly sent
        this.switchFormState("completed");
      } else {
        //if not
        console.log(res.err);
      }
    };
    await sendOrder(props, true, serverResponce);
  };

  createContractPDf = async () => {
    let nrOfAccessories = 0;
    Object.keys(this.state.productsValues).forEach((id) => {
      if (this.state.productsValues[id].accessory !== null) {
        nrOfAccessories++;
      }
    });
    /*const contractNumber = await getContractNumber(
      this.state.productsValues,
      this.state.formInformation,
      this.state.customerInformation
    );*/

    const contractNumber = await getContractNumber();

    console.log(contractNumber);

    await new Promise((resolve) => {
      this.setState(
        (prevState) => ({
          formInformation: {
            ...prevState.formInformation,
            contractNumber,
          },
        }),
        resolve
      );
    });
    const contract = ReactDomServer.renderToStaticMarkup(
      React.createElement(CreateContract, {
        formInformation: this.state.formInformation,
        productsValues: this.state.productsValues,
        customerInformation: this.state.customerInformation,
        labels: this.state.labels,
        accessories: this.state.accessories,
        params: this.state.params,
        switchFormState: this.switchFormState,
        products: this.state.products,
        nrOfAccessories: nrOfAccessories,
      })
    );
    const additionalPdfUrl =
      configSource.ROOT +
      `getimage?company=hcs&country=${this.state.params.lang.toLowerCase()}&type=lp_tos.pdf`;
    /*const firstPagePdf =
      configSource.ROOT +
      `getimage?company=hcs&country=${this.state.params.lang.toLowerCase()}&type=first_lp.pdf`;*/
    const firstPagePdf = "";
    try {
      const requestBody = {
        htmlContent: contract,
        additionalPdfUrl: additionalPdfUrl,
        firstPagePdf: firstPagePdf,
      };
      const response = await getContractPDF(requestBody);
      if (response.data) {
        const uint8Array = new Uint8Array(response.data);
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        const fileURL = window.URL.createObjectURL(blob);

        await new Promise((resolve) =>
          this.setState(
            {
              contractFile: {
                file: blob,
                fileName: "Hyreskontrakt_Lease_Plus.pdf",
                fileDisplay: fileURL,
                fileSize: blob.size,
              },
            },
            resolve
          )
        );
      } else {
        console.error("Failed to generate PDF");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  render() {
    if (!this.validateParams()) return <NotFound />;
    if (this.state.firstRender) return;

    const onBoarding =
      this.state.params.formType.toLowerCase() === "leaseplus" ||
      this.state.params.formType.toLowerCase() === "upcare";

    const disabled = !(
      (this.state.formInformation.quoteChecked &&
        !this.state.formInformation.orderChecked) ||
      (this.state.formInformation.orderChecked &&
        this.state.formInformation.idChecked &&
        (!this.state.formInformation.newDealerChecked ||
          this.state.newDealerInformation.verifyNewDealer) &&
        (!onBoarding ||
          this.state.formInformation.newDealerNotChecked ||
          this.state.formInformation.newDealerChecked)) ||
      configSource["DEBUG"]
    );

    let showQuote = true;
    const style_campaign = {
      color: "#003366",
      fontSize: "25px",
    };
    const style_campaign_extra = {
      color: "#003366",
      fontSize: "18px",
    };
    return !this.state.isVerified ? (
      <Stack className="form-wrapper" direction="column" alignItems="center">
        {this.state.params.lang.toLowerCase() === "be" && (
          <Stack className="select-wrapper" alignItems="stretch">
            <div className="margin-top">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  labelId="be-language-label"
                  id="be-language"
                  value={this.state.beLanguage}
                  label="Language"
                  onChange={this.handleSelectChange}
                  style={{ width: "100% !important" }}
                >
                  <MenuItem value={"nl"}>Dutch</MenuItem>
                  <MenuItem value={"fr"}>French</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Stack>
        )}
        <div className="completedText padding-bot">
          <h1 className="less-margin-bot">{this.state.labels.welcome}</h1>
          <p>{this.state.labels.enter_password}</p>
        </div>

        <Stack
          direction="column-reverse"
          justifyContent="center"
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={7}
        >
          <Stack direction="column" alignItems="center" spacing={5}>
            <img src="/husqvarna_logo.png" alt="Husqvarna" height="120px" />
            <Button
              variant="contained"
              sx={{
                minWidth: "100px",
                minHeight: "55px",
                mr: 0,
                backgroundColor: "#273A60",
              }}
              onClick={this.handleDealerButtonClick}
              disabled={true}
            >
              {this.state.labels.husqvarna_login}
            </Button>
            <FormHelperText
              sx={{
                width: "450px",
                "text-align": "center",
              }}
            >
              {this.state.labels.sso_down}
            </FormHelperText>
          </Stack>
          <Stack alignItems="stretch">
            <Stack className="header" direction="row">
              <TextField
                type={"password"}
                label="Password"
                id="password_input"
                variant="outlined"
                value={this.state.passwordInput}
                onChange={(e) => {
                  e.target.id = "password_input";
                  this.handleInput(e);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.target.id = "password_check";
                    this.handleClick(e);
                  }
                }}
              />
              <Button
                sx={{
                  minWidth: "100px",
                  minHeight: "55px",
                  ml: 2,
                  backgroundColor: "#273A60",
                }}
                id="password_check"
                variant="contained"
                onClick={(e) => {
                  this.handleClick(e);
                }}
                startIcon={<LoginIcon />}
              >
                {this.state.labels.login}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    ) : (
      <Stack className="form-wrapper" alignItems="stretch">
        <div>
          {this.state.params.lang.toLowerCase() === "be" && (
            <div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  labelId="be-language-label"
                  id="be-language"
                  value={this.state.beLanguage}
                  label="Language"
                  onChange={this.handleSelectChange}
                >
                  <MenuItem value={"nl"}>Dutch</MenuItem>
                  <MenuItem value={"fr"}>French</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          {this.props.params.formType === "leaseplus" &&
            this.props.params.lang.toLowerCase() === "no" && (
              <Modal
                open={this.state.modalOpen2}
                onClose={() => this.setState({ modalOpen2: false })}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    id="simple-modal-title"
                    sx={{ fontWeight: "bold" }}
                  >
                    {this.state.labels.important_information}
                  </Typography>
                  <Typography id="simple-modal-description" sx={{ mt: 2 }}>
                    {this.state.labels.snow_machine_campaign_no}
                  </Typography>
                  <Typography id="simple-modal-description" sx={{ mt: 2 }}>
                    {this.state.labels.end_of_campaign2}
                  </Typography>
                  <Typography id="simple-modal-description" sx={{ mt: 2 }}>
                    {this.state.labels.end_of_campaign3}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: "#273A60",
                      "&:hover": {
                        backgroundColor: darken("#273A60", 0.2), // Darken by 20%
                      },
                    }}
                    onClick={() => this.setState({ modalOpen2: false })}
                  >
                    OK
                  </Button>
                </Box>
              </Modal>
            )}
          {this.state.formInformation.formState === "edit" &&
            this.state.params.formType.toLowerCase() === "leaseplus" &&
            this.state.params.lang.toLowerCase() === "fr" &&
            this.state.invoiceDidNotMatch && (
              <InvoiceModal
                handleChange={this.handleInvoiceModalClose}
                invoiceDidNotMatch={this.state.invoiceDidNotMatch}
                labels={this.state.labels}
              />
            )}
          {this.state.formInformation.formState === "edit" && (
            <div>
              <Header
                text={this.state.labels.header1}
                imageSrc={this.state.headerImgSrc}
                params={this.state.params}
                imageWidth={"200px"}
                headerType={"header"}
                labels={this.state.labels}
                page={1}
              />
              {this.state.params.formType.toLowerCase() !== "flexprivate" && (
                <div className="padding-bot-small box-wrapper margin-bot">
                  <div className="titles-wrapp">
                    <h1 className="titles" style={{ marginLeft: "20px" }}>
                      {this.state.params.formType.toLowerCase() === "upcare"
                        ? this.state.labels.valueable_information +
                          " - " +
                          this.state.labels.valueable_information_upcare
                        : this.state.params.formType.toLowerCase() ===
                          "leaseplus"
                        ? this.state.labels.valueable_information +
                          " - " +
                          this.state.labels.valueable_information_leaseplus
                        : this.state.labels.valueable_information}
                    </h1>
                    <hr />
                  </div>
                  <div className="information_links">
                    <div
                      className="information_container"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto",
                        alignItems: "start",
                        gap: "16px",
                      }}
                    >
                      <div>
                        <p>
                          {this.state.labels.portal_usage_information + " "}
                          <span
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              await fetch(
                                configSource.ROOT +
                                  `getimage?company=hcs&country=${this.state.params.lang.toLowerCase()}&type=${this.state.params.formType.toLowerCase()}.pdf`
                              ).then((res) => window.open(res.url));
                            }}
                          >
                            <strong>{this.state.labels.here + "."}</strong>
                          </span>
                        </p>
                        {this.state.params.formType.toLowerCase() ===
                          "leaseplus" && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.getStringWithLinks(
                                this.state.labels.leaseplus_information
                              ),
                            }}
                          ></p>
                        )}
                        {this.state.params.formType.toLowerCase() ===
                          "upcare" && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.getStringWithLinks(
                                this.state.labels.upcare_information
                              ),
                            }}
                          ></p>
                        )}
                        {this.state.params.lang.toLowerCase() !== "nl" &&
                          this.state.params.formType.toLowerCase() !==
                            "construction" && (
                            <p>
                              {this.state.labels.invoice_information + " "}
                              <span
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={async () => {
                                  await fetch(
                                    configSource.ROOT +
                                      `getimage?company=hcs&country=${this.state.params.lang.toLowerCase()}&type=invoice.pdf`
                                  ).then((res) => window.open(res.url));
                                }}
                              >
                                <strong>{this.state.labels.here + "."}</strong>
                              </span>
                            </p>
                          )}
                        {this.state.params.lang.toLowerCase() === "fr" &&
                          this.state.params.formType.toLowerCase() !==
                            "construction" && (
                            <p>
                              {this.state.labels.guide_information + " "}
                              <span
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={async () => {
                                  await fetch(
                                    configSource.ROOT +
                                      `getimage?company=hcs&country=${this.state.params.lang.toLowerCase()}&type=guide.pdf`
                                  ).then((res) => window.open(res.url));
                                }}
                              >
                                <strong>{this.state.labels.here + "."}</strong>
                              </span>
                            </p>
                          )}
                        {(this.state.params.lang.toLowerCase() === "at" ||
                          this.state.params.lang.toLowerCase() === "de") &&
                          this.state.params.formType.toLowerCase() !==
                            "construction" && (
                            <p>
                              {this.state.labels.ayden_manual + " "}
                              <span
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={async () => {
                                  await fetch(
                                    configSource.ROOT +
                                      `getimage?company=hcs&country=${this.state.params.lang.toLowerCase()}&type=ayden_manual.pdf`
                                  ).then((res) => window.open(res.url));
                                }}
                              >
                                <strong>{this.state.labels.here + "."}</strong>
                              </span>
                            </p>
                          )}
                      </div>

                      <div
                        className="information_button"
                        style={{
                          display: "flex",
                          height: "100%", // Full height of the parent
                          alignItems: "center", // Centers the button vertically
                          justifyContent: "flex-end", // Aligns the button to the right
                          paddingRight: "16px",
                        }}
                      >
                        <Button
                          sx={{
                            backgroundColor: "#273A60", // Background color
                            color: "white", // Text color
                            padding: "8px 16px", // Padding for larger button
                            fontSize: "32px", // Increase font size for readability
                            "&:hover": {
                              backgroundColor: "#1A2D50", // Darker shade on hover
                            },
                          }}
                          onClick={async () => {
                            await fetch(
                              configSource.ROOT +
                                `getimage?company=hcs&country=${this.state.params.lang.toLowerCase()}&type=FAQ.pdf`
                            ).then((res) => window.open(res.url));
                          }}
                          variant="contained"
                        >
                          FAQ
                        </Button>
                      </div>
                    </div>

                    {this.props.params.formType.toLowerCase() === "leaseplus" &&
                      this.props.params.lang.toLowerCase() === "no" && (
                        <>
                          <p style={{ fontWeight: "bold" }}>
                            {this.state.labels.snow_machine_campaign_no}
                          </p>
                          <p style={{ fontWeight: "bold" }}>
                            {this.state.labels.end_of_campaign2}
                          </p>
                          <p style={{ fontWeight: "bold" }}>
                            {this.state.labels.end_of_campaign3}
                          </p>
                        </>
                      )}

                    {this.state.params.formType.toLowerCase() ===
                      "leaseplus" && (
                      <div className="pre-wrapper">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.labels.steps_information,
                          }}
                        ></p>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div>
                <strong>
                  <span style={style_campaign}>
                    {this.state.labels.campaign_header}
                  </span>
                </strong>
              </div>
              <div>
                <strong>
                  <span style={style_campaign_extra}>
                    {this.state.labels.campaign_header_extra}
                  </span>
                </strong>
              </div>
              {this.state.labels.hcs_information !== "" &&
                this.state.labels?.hcs_information !== undefined && (
                  <div>
                    <Information
                      label={this.state.labels.hcs_information}
                      changeHandler={this.handleFormInformationChange}
                    ></Information>
                  </div>
                )}
              {this.state.params.formType.toLowerCase() !== "leaseplus" && (
                <div className="padding-top">
                  <Tooltip title={this.state.labels.tooltip_upload_formdata}>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        minWidth: "400px",
                        minHeight: "36.5px",
                        float: "right",
                        backgroundColor: "#273A60",
                      }}
                    >
                      <UploadIcon sx={{ marginLeft: "350px" }} />
                      <span className="app-span-text">
                        {this.state.labels.upload_formdata}
                      </span>
                      <input
                        type="file"
                        accept=".json"
                        hidden
                        onChange={(event) => {
                          var reader = new FileReader();
                          reader.addEventListener(
                            "load",
                            () => {
                              //jumps here when its done with 'readAsText'
                              let parsed = JSON.parse(reader.result);
                              Object.keys(parsed.productsValues).forEach(
                                (key) => {
                                  if (
                                    parsed.productsValues[key].deliveryDate !==
                                    null
                                  ) {
                                    parsed.productsValues[key].deliveryDate =
                                      new Date(
                                        parsed.productsValues[key].deliveryDate
                                      );
                                  }
                                }
                              );
                              if (
                                parsed.customerInformation.dateOfBirth !== null
                              ) {
                                parsed.customerInformation.dateOfBirth =
                                  new Date(
                                    parsed.customerInformation.dateOfBirth
                                  );
                              }
                              if (
                                parsed.formInformation.useCampaign &&
                                !this.state.campaign
                              ) {
                                parsed.formInformation.useCampaign = false;
                              }
                              this.setState(parsed);
                            },
                            false
                          );
                          reader.readAsText(event.target.files[0]);
                        }}
                      />
                    </Button>
                  </Tooltip>
                </div>
              )}
              <div className="titles-wrapp">
                <h1 className="titles">{this.state.labels.main_products}</h1>
                <hr />
              </div>
              <div>
                <FormControl fullWidth>
                  <InputLabel id="contractLength-label">
                    {this.state.labels.contract_length}
                  </InputLabel>
                  <Select
                    labelId="contractLength-label"
                    id="contractLength-select"
                    value={this.state.formInformation.contractLength}
                    label={this.state.labels.contract_length}
                    onChange={this.changeContractLength}
                  >
                    {this.state.formInformation.contractLengths.map(
                      (length) => {
                        return (
                          <MenuItem key={length} value={length}>
                            {length.toString() + " " + this.state.labels.months}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </div>
              {Object.keys(this.state.productsValues).map((id) => {
                return this.generateProduct(id);
              })}
              <div className="padding-top row ">
                <Tooltip title={this.state.labels.tooltip_add_product || ""}>
                  <Button
                    sx={{
                      backgroundColor: "#273A60",
                    }}
                    onClick={() => {
                      this.addAnotherProduct();
                    }}
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    {this.state.labels.add_product}
                  </Button>
                </Tooltip>
                {Object.keys(this.state.productsValues).length > 1 && (
                  <div className="padding-left">
                    <Tooltip
                      title={this.state.labels.tooltip_remove_product || ""}
                    >
                      <Button
                        onClick={() => {
                          this.removeProduct();
                        }}
                        color="error"
                        variant="contained"
                        startIcon={<RemoveCircleOutlineIcon />}
                      >
                        {this.state.labels.remove_product}
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </div>
              {(this.state.formInformation.customer === "Business" ||
                this.state.params.formType.toLowerCase() === "standard") && (
                <div className="padding-top">
                  <div className="titles-wrapp">
                    <h1 className="titles">
                      {this.state.params.formType.toLowerCase() === "upcare"
                        ? this.state.labels.accessories_title_2
                        : this.state.params.formType.toLowerCase() ===
                            "construction" ||
                          this.state.params.formType.toLowerCase() === "flexpro"
                        ? this.state.labels.accessories_title_construction
                        : this.state.params.formType.toLowerCase() ===
                          "standard"
                        ? this.state.labels.additional_cost_title
                        : "Inget av de"}
                    </h1>
                    <hr />
                  </div>
                  <div className="accessories padding-bot">
                    {Object.keys(this.state.accessories).map((id) => {
                      return this.generateAccessories(id);
                    })}
                  </div>
                  <div className="row">
                    <Tooltip
                      title={this.state.labels.tooltip_add_accessory || ""}
                    >
                      <Button
                        sx={{
                          backgroundColor: "#273A60",
                        }}
                        onClick={() => {
                          this.addAccessories();
                        }}
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                      >
                        {this.state.labels.add_accessory}
                      </Button>
                    </Tooltip>
                    {Object.keys(this.state.accessories).length > 0 && (
                      <div className="padding-left">
                        <Tooltip
                          title={
                            this.state.labels.tooltip_remove_accessory || ""
                          }
                        >
                          <Button
                            onClick={() => {
                              this.removeAccessories();
                            }}
                            variant="contained"
                            color="error"
                            startIcon={<RemoveCircleOutlineIcon />}
                          >
                            {this.state.labels.remove_accessory}
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="padding-top">
                <hr />
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>
                      {this.state.labels.tooltip_order_quote || ""}
                    </span>
                  }
                  placement="top"
                >
                  <FormGroup sx={{ width: "50%" }}>
                    {showQuote && (
                      <FormControlLabel
                        className="quote_order"
                        control={
                          <Checkbox
                            id="quote_check"
                            checked={this.state.formInformation.quoteChecked}
                            onChange={this.handleChecked}
                            sx={{ width: "10%" }}
                          />
                        }
                        sx={{ width: "100%" }}
                        label={this.state.labels.quote_button}
                      />
                    )}
                    <FormControlLabel
                      className="quote_order"
                      control={
                        <Checkbox
                          id="order_check"
                          checked={this.state.formInformation.orderChecked}
                          onChange={this.handleChecked}
                          sx={{ width: "10%" }}
                        />
                      }
                      sx={{ width: "100%" }}
                      label={this.state.labels.order_button}
                    />
                  </FormGroup>
                </Tooltip>
              </div>
              <hr />
              {this.state.formInformation.orderChecked && (
                <div className="padding-top">
                  <div className="titles-wrapp">
                    <h1 className="titles">
                      {this.state.labels.customer_information_title}
                    </h1>
                    <hr />
                  </div>
                  {this.state.formInformation.customer === "Business" ? (
                    <CustomerInformationBusiness
                      changeHandler={this.handleCustomerInfoChange}
                      customerInformation={this.state.customerInformation}
                      formInformation={this.state.formInformation}
                      labels={this.state?.labels}
                      addSignatory={this.addSignatory}
                      removeSignatory={this.removeSignatory}
                      formType={this.state.params.formType.toLowerCase()}
                      params={this.state.params}
                    />
                  ) : (
                    <div>
                      {socialSecurityNumberCountries.includes(
                        this.state.params.lang.toLowerCase()
                      ) &&
                      this.state.formInformation.fillBySSN &&
                      (this.state.params.lang.toLowerCase() === "se" ||
                        this.state.params.lang.toLowerCase() === "no") ? (
                        <FillBySSN
                          changeHandler={this.handleCustomerInfoChange}
                          formInfoChange={this.handleFormInformationChange}
                          customerInformation={this.state.customerInformation}
                          formInformation={this.state.formInformation}
                          labels={this.state?.labels}
                          params={this.state.params}
                        />
                      ) : (
                        <CustomerInformationPrivate
                          changeHandler={this.handleCustomerInfoChange}
                          customerInformation={this.state.customerInformation}
                          formInformation={this.state.formInformation}
                          labels={this.state?.labels}
                          params={this.state.params}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
              {this.state.formInformation.quoteChecked && (
                <div className="padding-top">
                  <div className="titles-wrapp">
                    <h1 className="titles">
                      {this.state.labels.customer_information_title}
                    </h1>
                    <hr />
                  </div>
                  <QuoteCustomerInformation
                    changeHandler={this.handleCustomerInfoChange}
                    customerInformation={this.state.customerInformation}
                    formInformation={this.state.formInformation}
                    labels={this.state?.labels}
                    params={this.state.params}
                    customerType={this.state.formInformation.customer}
                  />
                </div>
              )}
              {this.state.formInformation.orderChecked &&
              !this.state.formInformation.fillBySSN ? (
                <div className="padding-top">
                  <CustomerInformationIdentification
                    customerInformation={this.state.customerInformation}
                    formInformation={this.state.formInformation}
                    labels={this.state?.labels}
                    params={this.state.params}
                    extraFiles={this.state.extraFiles}
                    idSelectHandler={this.handleIdSelect}
                    pepSelectHandler={this.handlePepSelect}
                    addExtraFiles={this.addExtraFiles}
                    changeHandler={this.handleCustomerInfoChange}
                    removeExtraFiles={this.removeExtraFiles}
                    checkHandler={this.handleChecked}
                    formType={this.state.params.formType.toLowerCase()}
                    productsValues={this.state.productsValues}
                  />
                </div>
              ) : null}
              {this.state.formInformation.quoteChecked ||
              this.state.formInformation.orderChecked ? (
                <div className="padding-top">
                  <div className="titles-wrapp">
                    <h1 className="titles">
                      {this.state.labels.dealer_information}
                    </h1>
                    <hr />
                  </div>
                  <DealerInformation
                    changeHandler={this.handleCustomerInfoChange}
                    customerInformation={this.state.customerInformation}
                    formInformation={this.state.formInformation}
                    labels={this.state?.labels}
                    params={this.state.params}
                    checkHandler={this.handleChecked}
                  />
                </div>
              ) : null}
              {this.state.formInformation.orderChecked && onBoarding && (
                <>
                  <div className="padding-onboarding-title">
                    <h1 className="titles-onboarding">
                      {this.state.labels.first_order}
                    </h1>
                    <div className="padding-checkbox">
                      <FormGroup row={true}>
                        <FormControlLabel
                          className="new_dealer"
                          control={
                            <Checkbox
                              id="new_dealer_yes"
                              checked={
                                this.state.formInformation.newDealerChecked
                              }
                              onChange={this.handleChecked}
                            />
                          }
                          label={this.state.labels.positive_answer}
                        />
                        <FormControlLabel
                          className="new_dealer"
                          control={
                            <Checkbox
                              id="new_dealer_no"
                              checked={
                                this.state.formInformation.newDealerNotChecked
                              }
                              onChange={this.handleChecked}
                            />
                          }
                          label={this.state.labels.negative_answer}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <hr />
                </>
              )}
              {this.state.formInformation.newDealerChecked && (
                <div className="padding-top-small">
                  <div className="titles-wrapp">
                    <h1 className="titles">
                      {this.state.labels.new_dealer_title}
                    </h1>
                    <hr />
                  </div>
                  <NewDealer
                    newDealer={this.state.newDealerInformation}
                    labels={this.state?.labels}
                    changeHandler={this.handleNewDealerInfoChange}
                    formInformation={this.state.formInformation}
                    checkHandler={this.handleChecked}
                    params={this.state.params}
                    addSignatory={this.addSignatory}
                    removeSignatory={this.removeSignatory}
                    payment={false}
                  />
                </div>
              )}
              {(this.state.params.formType.toLowerCase() === "upcare" ||
                this.state.params.formType.toLowerCase() === "flexpro" ||
                this.state.params.formType.toLowerCase() === "construction" ||
                this.state.params.formType.toLowerCase() === "leaseplus") &&
                this.state.formInformation.orderChecked && (
                  <div className="padding-top">
                    <div className="titles-wrapp">
                      <h1 className="titles">
                        {this.state.labels.extra_information}
                      </h1>
                      <hr />
                    </div>
                    <Information
                      label={this.state.labels.other}
                      extraInfo={this.state.formInformation.extraInfo}
                      changeHandler={this.handleFormInformationChange}
                    ></Information>
                  </div>
                )}
              {this.state.params.formType.toLowerCase() === "flexpro" ||
              this.state.params.formType.toLowerCase() === "construction" ? (
                <div className="padding-top totalValues">
                  <DisplayField
                    id="totalPrice"
                    value={(
                      this.state.formInformation.totalPrice -
                      this.state.formInformation.totalAccessoriesPrice
                    ).toString()}
                    label={this.state.labels.total_product_price}
                    currency={this.state.labels.currency}
                    sx={{
                      width:
                        this.state.params.lang.toLowerCase() === "at"
                          ? "25%"
                          : "33%",
                    }}
                  />
                  <DisplayField
                    id="totalAccessoriesPrice"
                    value={this.state.formInformation.totalAccessoriesPrice.toString()}
                    label={
                      this.state.labels.label_total_price +
                      " " +
                      (this.state.params.formType.toLowerCase() === "flexpro"
                        ? this.state.labels.accessories_title
                        : this.state.labels.accessories_title_construction)
                    }
                    currency={this.state.labels.currency}
                    sx={{
                      width:
                        this.state.params.lang.toLowerCase() === "at"
                          ? "25%"
                          : "33%",
                    }}
                  />
                  <DisplayField
                    id="totalMonthlyFee"
                    value={this.state.formInformation.totalResidue.toString()}
                    label={this.state.labels.total_residual_value}
                    currency={this.state.labels.currency}
                    sx={{
                      width:
                        this.state.params.lang.toLowerCase() === "at"
                          ? "25%"
                          : "33%",
                    }}
                  />
                  {this.state.params.lang.toLowerCase() === "at" && (
                    <DisplayField
                      id="totalMonthlyFee"
                      value={
                        this.state.formInformation.totalMonthlyFee *
                        (this.state.formInformation.contractLength + 1) *
                        0.01 *
                        1.2
                      }
                      label={this.state.labels.extra_fee}
                      currency={this.state.labels.currency}
                      sx={{ width: "25%" }}
                    />
                  )}
                </div>
              ) : null}
              <div className="padding-top padding-bot">
                <DisplayField
                  id="totalMonthlyFee"
                  value={this.state.formInformation.totalMonthlyFee.toString()}
                  label={
                    this.state.params.formType.toLowerCase() === "leaseplus"
                      ? this.state.labels.total_monthly_fee +
                        "" +
                        this.state.labels.inc_vat
                      : this.state.labels.total_monthly_fee
                  }
                  currency={this.state.labels.currency}
                />
              </div>
              <div className="campaign">
                {this.state.campaign && (
                  <>
                    <TextField
                      id={this.state.labels.campaign_description}
                      defaultValue={this.state.labels.campaign_description}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                      fullWidth={true}
                    />
                    <FormControlLabel
                      className="campaign"
                      control={
                        <Checkbox
                          id="campaign"
                          checked={this.state.formInformation.useCampaign}
                          onChange={() => {
                            let state = this.state;
                            state.formInformation.useCampaign =
                              !state.formInformation.useCampaign;
                            this.setState(state);
                          }}
                        />
                      }
                      label={this.state.labels.campaign_checkbox}
                    />
                  </>
                )}
              </div>
              <div className="button-container">
                <div className="button-container-row">
                  {this.state.params.formType.toLowerCase() !== "leaseplus" && (
                    <Tooltip
                      title={this.state.labels.tooltip_download_formdata}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        sx={{
                          minWidth: "415px",
                          minHeight: "45px",
                          backgroundColor: "#273A60",
                        }}
                        onClick={() => {
                          const content = {
                            productsValues: this.state.productsValues,
                            accessories: this.state.accessories,
                            customerInformation: this.state.customerInformation,
                            formInformation: this.state.formInformation,
                          };
                          const filename = `hcs-${this.state.params.lang.toLowerCase()}-${this.state.params.formType.toLowerCase()}.json`;

                          const a = document.createElement("a");
                          const file = new Blob([JSON.stringify(content)], {
                            type: "json",
                          });
                          a.href = URL.createObjectURL(file);
                          a.download = filename;
                          a.click();

                          URL.revokeObjectURL(a.href);
                        }}
                      >
                        <DownloadIcon sx={{ marginLeft: "380px" }} />
                        <span className="app-span-text">
                          {this.state.labels.download_formdata}
                        </span>
                      </Button>
                    </Tooltip>
                  )}

                  <Button
                    sx={{
                      minWidth: "350px",
                      minHeight: "45px",
                      backgroundColor: "#273A60",
                      marginLeft:
                        this.state.params.formType.toLowerCase() === "leaseplus"
                          ? "auto"
                          : "0",
                    }}
                    onClick={async () => {
                      if (this.validateFormInputs()) {
                        try {
                          if (this.props.params.lang === "fr") {
                            this.setState({ isLoading: true });
                            const result = await uploadInvoiceForChecking(
                              this.state.extraFiles[0],
                              this.state.customerInformation.customerFirstName,
                              this.state.customerInformation.customerLastName,
                              this.state.customerInformation
                                .customerHouseNumber +
                                " " +
                                this.state.customerInformation.customerStreet +
                                " " +
                                this.state.customerInformation.customerZipCode +
                                " " +
                                this.state.customerInformation.customerCity
                            );
                            if (result) {
                              this.switchFormState("review");
                            } else {
                              this.setState({ invoiceDidNotMatch: true });
                            }
                          } else {
                            this.switchFormState("review");
                          }
                        } catch (error) {
                          console.error("Error during invoice check:", error);
                        } finally {
                          this.setState({ isLoading: false });
                        }
                      }
                    }}
                    variant="contained"
                    disabled={disabled}
                  >
                    {this.state.isLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <ArrowForwardRoundedIcon sx={{ marginLeft: "300px" }} />
                        <span className="app-span-text">
                          {this.state.labels.preview_form}{" "}
                        </span>
                      </>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {this.state.formInformation.formState === "review" && (
            <div>
              <Header
                text={
                  this.state.formInformation.orderChecked
                    ? this.state.labels.header2
                    : this.state.labels.header3
                }
                imageSrc={this.state.headerImgSrc}
                params={this.state.params}
                imageWidth={"200px"}
                headerType={"header"}
                labels={this.state.labels}
                page={2}
              />

              <div className="padding-top">
                <FormRes
                  formInformation={this.state.formInformation}
                  productsValues={this.state.productsValues}
                  customerInformation={this.state.customerInformation}
                  labels={this.state.labels}
                  accessories={this.state.accessories}
                  extraFiles={this.state.extraFiles}
                  params={this.state.params}
                  pdf={false}
                  switchFormState={this.switchFormState}
                  products={this.state.products.productList1}
                  productAccessories={this.state.accessories}
                  variables={this.state.variables}
                  newDealerInformation={this.state.newDealerInformation}
                  changeContractLength={this.changeContractLength}
                  headerImgSrc={this.state.headerImgSrc}
                  createContractPDf={this.createContractPDf}
                ></FormRes>
              </div>
            </div>
          )}
          {this.state.formInformation.formState ===
            "confidentiality_notice" && (
            <div>
              <Header
                text={this.state.labels.confidentiality_header}
                imageSrc={this.state.headerImgSrc}
                params={this.state.params}
                imageWidth={"200px"}
                headerType={"header"}
                labels={this.state.labels}
              />
              <div className="padding-top">
                <ConfidentialityNotice
                  labels={this.state.labels}
                  params={this.state.params}
                  switchFormState={this.switchFormState}
                ></ConfidentialityNotice>
              </div>
            </div>
          )}
          {this.state.formInformation.formState === "credit_check" && (
            <div>
              <Header
                text={this.state.labels.credit_check_header}
                imageSrc={this.state.headerImgSrc}
                params={this.state.params}
                imageWidth={"200px"}
                headerType={"header"}
                labels={this.state.labels}
              />
              <div className="padding-top">
                <CreditCheck
                  formInformation={this.state.formInformation}
                  productsValues={this.state.productsValues}
                  customerInformation={this.state.customerInformation}
                  labels={this.state.labels}
                  accessories={this.state.accessories}
                  params={this.state.params}
                  switchFormState={this.switchFormState}
                  products={this.state.products.productList1}
                  productAccessories={this.state.products?.accessories}
                  variables={this.state.variables}
                  newDealerInformation={this.state.newDealerInformation}
                  changeHandlerCustomer={this.handleCustomerInfoChange}
                  changeHandlerForm={this.handleFormInformationChange}
                  changeHandlerCredit={this.handleCreditInfoChange}
                  changeHandlerContractFile={this.handleContractFileChange}
                  changeHandlerCreditFile={this.handleCreditFileChange}
                  createContractPDf={this.createContractPDf}
                  sendBeforeCerditCheck={this.sendBeforeCerditCheck}
                ></CreditCheck>
              </div>
            </div>
          )}
          {this.state.formInformation.formState === "create_contract" && (
            <div>
              <Header
                text={this.state.labels.contract_header}
                imageSrc={this.state.headerImgSrc}
                params={this.state.params}
                imageWidth={"200px"}
                headerType={"header"}
                labels={this.state.labels}
              />

              <div className="padding-top">
                <DisplayContract
                  creditFile={this.state.creditFile}
                  contractFile={this.state.contractFile}
                  labels={this.state.labels}
                  params={this.state.params}
                  switchFormState={this.switchFormState}
                  formInformation={this.state.formInformation}
                  productsValues={this.state.productsValues}
                  customerInformation={this.state.customerInformation}
                  accessories={this.state.accessories}
                  extraFiles={this.state.extraFiles}
                  products={this.state.products}
                  variables={this.state.variables}
                  newDealerInformation={this.state.newDealerInformation}
                  headerImgSrc={this.state.headerImgSrc}
                  sendContract={this.sendContract}
                  changeHandler={this.handleCustomerInfoChange}
                ></DisplayContract>
              </div>
            </div>
          )}
          {this.state.formInformation.formState === "completed" && (
            <div>
              <Header
                imageSrc={this.state.headerImgSrc}
                params={this.state.params}
                imageWidth={"200px"}
                headerType={"header"}
                labels={this.state.labels}
              />
              <div className="completedText">
                <h1>{this.state.labels.thank_you}</h1>
                {this.state.formInformation.orderChecked && (
                  <>
                    <div>
                      <p>{this.state.labels.order_sent}</p>
                    </div>
                    <div className="order_info">
                      <p>{this.state.labels.order_info}</p>
                    </div>
                  </>
                )}
                {this.state.formInformation.quoteChecked && (
                  <div>
                    <p>{this.state.labels.quote_downloaded}</p>
                  </div>
                )}
                <Button
                  sx={{
                    backgroundColor: "#273A60", // Background color
                    color: "white", // Text color
                    padding: "8px 16px", // Padding for larger button
                    fontSize: "32px", // Increase font size for readability
                    "&:hover": {
                      backgroundColor: "#1A2D50", // Darker shade on hover
                    },
                  }}
                  onClick={async () => {
                    await fetch(
                      configSource.ROOT +
                        `getimage?company=hcs&country=${this.state.params.lang.toLowerCase()}&type=FAQ.pdf`
                    ).then((res) => window.open(res.url));
                  }}
                  variant="contained"
                >
                  FAQ
                </Button>
              </div>
            </div>
          )}
          <br></br>
        </div>
        {this.state.formInformation.orderChecked && (
          <div className="padding-top">
            <StepperComponent
              steps={this.state.stepLabels}
              activeStep={this.state.stepCounter}
            ></StepperComponent>
          </div>
        )}
      </Stack>
    );
  }
}

export default withParams(Hcs);
